import React from "react";
import Layout from "../components/layout";
import { filterArrayByType } from "../utility";
import SEO from "../components/seo";

const CaseStudyPage = ({ pageContext }) => {
  console.log("PAGE CONTEXT", pageContext);

  let caseStudy = pageContext.node.data;
  console.log("caseStudy", caseStudy);
  let calloutBox = filterArrayByType(caseStudy.body, "callout_box")[0];
  console.log("callout", calloutBox);

  return (
    <Layout>
      <SEO
        title={caseStudy.meta_title}
        image={caseStudy.meta_og_image.url}
        description={caseStudy.meta_description}
        noindex={caseStudy.meta_noindex}
        canonical={
          typeof window !== "undefined" &&
          window.location.origin + window.location.pathname
        }
      />

      <div
        className="title-bar title-bar-xl banner-work-case-1"
        alt={caseStudy.case_study_wide_image.alt}
        style={{
          backgroundImage: `url(${caseStudy.case_study_wide_image.url})`,
        }}
      >
        <div className="container">
          <a href="" className="page-scroll">
            <span className="icn-arrows-down">[Down Arrow]</span>
          </a>
          <div className="wrapper">
            <h2 className="page-title">Case Study</h2>
            <div className="title-bar-accent"></div>
          </div>
        </div>
      </div>

      {/*  PAGE BODY  */}
      <section id="body-area">
        <div className="container">
          <div className="inset">
            <h1 className="headline">{caseStudy.case_study_title.text}</h1>
            <div className="row">
              <div
                className="col-md-7"
                dangerouslySetInnerHTML={{
                  __html: caseStudy.case_study_body_text.html,
                }}
              ></div>

              <aside className="col-md-5">
                <div className="box style-1">
                  <h3 className="tag">
                    {calloutBox.primary.callout_box_label.text || ""}
                  </h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        calloutBox.primary.callout_box_body_text.html || "",
                    }}
                  ></div>
                  {calloutBox.primary.callout_box_cta_link && (
                    <a
                      href={calloutBox.primary.callout_box_cta_link.url}
                      className="btn btn-primary"
                    >
                      {calloutBox.primary.callout_box_cta_text} →
                    </a>
                  )}
                </div>
              </aside>
            </div>{" "}
            {/*  /row  */}
          </div>{" "}
          {/*  /box  */}
          <div className="bar">
            <div className="left">
              <a
                className={`${pageContext.prev || "disabled"}`}
                href={`/case-study/${
                  pageContext.prev && pageContext.prev.uid
                }/`}
              >
                <span className="icn-chevron-left-sm"></span> PREVIOUS{" "}
                <span className="lg-screen-only">WORK CASE</span>
              </a>
            </div>
            <div className="right">
              <a
                className={`${pageContext.next || "disabled"}`}
                href={`/case-study/${
                  pageContext.next && pageContext.next.uid
                }/`}
              >
                NEXT <span className="icn-chevron-right-sm"></span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default CaseStudyPage;
